
import { defineComponent, ref } from 'vue'

import LogItem from '@/components/Log/LogItem.vue'

import { Entry } from '@/models/models'
import {
  sameDay,
  getDateReference
} from '@/utils/date-utils'
import { StoreService, useServiceInjector } from '@/injection'
import { useExtendedRouter } from '@/router/extended-router'

const PAGE_SIZE = 8

type LogItemData = {
  entry?: Entry;
  text?: string;
}

const createEntryItem = (entry: Entry): LogItemData => ({entry})
const createHeaderItem = (entry: Entry): LogItemData => ({
  text: getDateReference(entry.datetime)
})

export default defineComponent({
  name: "log-page", //Need to define this for keep-alive in App.vue
  components: {
    LogItem
  },
  deactivated() {
    this.active = false
  },
  activated() {
    this.active = true
    this.noMoreEntries = false
    if (this.numLoaded > 0) {
      console.log("Log activated, refreshing " + this.numLoaded + " items")
      this.refresh().then(() => {
        if(this.numLoaded == 0) {
          this.$router.replace('/')
        }
      })
    }
  },
  setup() {
    const inject = useServiceInjector()

    const store = inject(StoreService)

    const router = useExtendedRouter()

    if (store.mostRecentEntrySync.value == null) router.replace('/')

    const processEntries = (entries: Entry[], lastEntry: Entry|null): LogItemData[] => {
      const res: LogItemData[] = []
      entries.reduce((prev: Entry | null, entry: Entry) => {
        if (prev == null || !sameDay(entry.datetime, prev.datetime)) {
          res.push(createHeaderItem(entry))
        }
        res.push(createEntryItem(entry))
        return entry
      }, lastEntry ?? null)
      return res
    }

    const active = ref(true)

    const numLoaded = ref(0)

    const logItems = ref<LogItemData[]>([])

    const lastEntry = ref<Entry|null>(null)

    const noMoreEntries = ref(false)

    const loading = ref(false)

    const refresh = async () => {
      loading.value = true

      const entries = await store.getEntries(numLoaded.value, 0)
      logItems.value = processEntries(entries, null)
      lastEntry.value = entries.length > 0 ? entries[entries.length-1] : null
      numLoaded.value = entries.length
      loading.value = false
    }

    const loadMore = async () => {
      if (loading.value || noMoreEntries.value) return

      loading.value = true

      const entries = await store.getEntries(PAGE_SIZE, numLoaded.value)
      if (entries.length < PAGE_SIZE)
        noMoreEntries.value = true

      logItems.value = [...logItems.value, ...processEntries(entries, lastEntry.value)]

      lastEntry.value = entries.length > 0 ? entries[entries.length-1] : null
      numLoaded.value += entries.length
      loading.value = false
    }

    return {
      refresh,
      logItems,
      noMoreEntries,
      loading,
      loadMore,
      numLoaded,
      active,
      back: () => router.goBackSafely('/', false)
    }
  }
})
