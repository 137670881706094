
import { defineComponent } from 'vue'

import Levels from '@/components/Visualization/Levels.vue'
import EntryNotes from '@/components/Log/EntryNotes.vue'
import { Entry } from '@/models/models'

export default defineComponent({
  components: {
    Levels,
    EntryNotes
  },
  props: {
    entry: {
      type: Object as () => Entry,
      required: true,
    }
  },
})
