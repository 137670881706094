<template>
  <router-link class="undecorated-link log-item-container"
              :to="{ name: 'LogEntry', params: { id: entry.id }}">
    <section class="log-item">
      <levels class="levels" v-bind="entry.levels" updateFavicon/>
      <entry-notes class="text" :notes="entry.notes"/>
    </section>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Levels from '@/components/Visualization/Levels.vue'
import EntryNotes from '@/components/Log/EntryNotes.vue'
import { Entry } from '@/models/models'

export default defineComponent({
  components: {
    Levels,
    EntryNotes
  },
  props: {
    entry: {
      type: Object as () => Entry,
      required: true,
    }
  },
})
</script>

<style>
.log-item {
  display: flex;
  position: relative;
  margin-bottom: 1vmin;
}
.log-item .text {
  align-self: center;
  flex: 1;
}
.log-item .levels {
  width: 10vmin;
  height: 10vmin;
  margin: 0 10px 0 0;
}
.log-item-container:not(:last-child) .log-item:before {
  content: '';
  width: 2px;
  height: calc(100% - 11vmin);
  position: absolute;
  left: 5vmin;
  bottom: 0;
  background-color: var(--white-transparent);
}
</style>